<template>
  <div style="width:100%">
      
    <StudentOrderList v-if="editType == 'list'" @changeShowType="changeShowType"></StudentOrderList>

    <StuOrderInfo v-if="editType != 'list'" :editId="editId"  :editType="editType" @changeShowType="changeShowType"></StuOrderInfo>
      
  </div>
</template>

<script>
import StudentOrderList from './components/StudentOrderList'
import StuOrderInfo from '../components/StuOrderInfo'
import {  } from '@/api/index.js'
export default {
  name: 'stuOrder',
  components: {  StudentOrderList,StuOrderInfo},
  data() {
    return {
      editType:'list',
      editId:'',
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,id){
      if(type=='edit'){
        this.editId = id;
      }
      this.editType = type;
    }
  }
}
</script>

<style>
</style>